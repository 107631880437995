/**
 * These files are generated from design tokens defind using Token Studio
 */

@layer ds.theme.color-mode.light {
:root, [data-ds-color-mode="light"] {
  --ds-global-blue-1: #fefeff;
  --ds-global-blue-2: #edf5fa;
  --ds-global-blue-3: #d3e5f4;
  --ds-global-blue-4: #b7d5ed;
  --ds-global-blue-5: #9bc5e5;
  --ds-global-blue-6: #94c1e3;
  --ds-global-blue-7: #2f86c9;
  --ds-global-blue-8: #075089;
  --ds-global-blue-9: #0A71C0;
  --ds-global-blue-10: #085ea0;
  --ds-global-blue-11: #074b80;
  --ds-global-blue-12: #0966ac;
  --ds-global-blue-13: #042d4d;
  --ds-global-blue-contrast-1: #ffffff;
  --ds-global-blue-contrast-2: #f3f8fc;
  --ds-global-green-1: #fcfefc;
  --ds-global-green-2: #ecf6ed;
  --ds-global-green-3: #cfe9d3;
  --ds-global-green-4: #b3dcb8;
  --ds-global-green-5: #95ce9d;
  --ds-global-green-6: #8bca94;
  --ds-global-green-7: #189528;
  --ds-global-green-8: #045a10;
  --ds-global-green-9: #078D19;
  --ds-global-green-10: #067615;
  --ds-global-green-11: #056011;
  --ds-global-green-12: #067314;
  --ds-global-green-13: #033409;
  --ds-global-green-contrast-1: #000000;
  --ds-global-green-contrast-2: #000401;
  --ds-global-orange-1: #fffefd;
  --ds-global-orange-2: #fbf1ec;
  --ds-global-orange-3: #f4ddd0;
  --ds-global-orange-4: #eecab7;
  --ds-global-orange-5: #e7b69c;
  --ds-global-orange-6: #e5b094;
  --ds-global-orange-7: #cc632b;
  --ds-global-orange-8: #7e3a15;
  --ds-global-orange-9: #CA5C21;
  --ds-global-orange-10: #a94d1c;
  --ds-global-orange-11: #8a3f17;
  --ds-global-orange-12: #a1491a;
  --ds-global-orange-13: #47210c;
  --ds-global-orange-contrast-1: #000000;
  --ds-global-orange-contrast-2: #0d0602;
  --ds-global-purple-1: #fefefe;
  --ds-global-purple-2: #f5f2f9;
  --ds-global-purple-3: #e7dfef;
  --ds-global-purple-4: #d9cce6;
  --ds-global-purple-5: #ccbadd;
  --ds-global-purple-6: #c7b4da;
  --ds-global-purple-7: #9572b9;
  --ds-global-purple-8: #663399;
  --ds-global-purple-9: #663299;
  --ds-global-purple-10: #4f2777;
  --ds-global-purple-11: #381b54;
  --ds-global-purple-12: #7b4ea7;
  --ds-global-purple-13: #3b1d59;
  --ds-global-purple-contrast-1: #ffffff;
  --ds-global-purple-contrast-2: #eee8f3;
  --ds-global-red-1: #fffefe;
  --ds-global-red-2: #fbf1f1;
  --ds-global-red-3: #f5dcdc;
  --ds-global-red-4: #f0c7c7;
  --ds-global-red-5: #eab3b3;
  --ds-global-red-6: #e8acac;
  --ds-global-red-7: #d25b5b;
  --ds-global-red-8: #9a1616;
  --ds-global-red-9: #C01B1B;
  --ds-global-red-10: #9a1616;
  --ds-global-red-11: #771111;
  --ds-global-red-12: #c22020;
  --ds-global-red-13: #5a0d0d;
  --ds-global-red-contrast-1: #ffffff;
  --ds-global-red-contrast-2: #f6dfdf;
  --ds-global-yellow-1: #fffefc;
  --ds-global-yellow-2: #fbf2d3;
  --ds-global-yellow-3: #f5e19b;
  --ds-global-yellow-4: #efcf5d;
  --ds-global-yellow-5: #e6bc27;
  --ds-global-yellow-6: #e0b726;
  --ds-global-yellow-7: #9a7e1a;
  --ds-global-yellow-8: #5d4c10;
  --ds-global-yellow-9: #EABF28;
  --ds-global-yellow-10: #d0aa24;
  --ds-global-yellow-11: #b7951f;
  --ds-global-yellow-12: #776114;
  --ds-global-yellow-13: #352b09;
  --ds-global-yellow-contrast-1: #000000;
  --ds-global-yellow-contrast-2: #382d0a;
  --ds-color-accent-1: #FEFEFE;
  --ds-color-accent-2: #F5F9FF;
  --ds-color-accent-3: #E0EEFF;
  --ds-color-accent-4: #C2DDFF;
  --ds-color-accent-5: #A3CDFF;
  --ds-color-accent-6: #A3CDFF;
  --ds-color-accent-7: #4791EB;
  --ds-color-accent-8: #0053B8;
  --ds-color-accent-9: #0053B8;
  --ds-color-accent-10: #003575;
  --ds-color-accent-11: #001E42;
  --ds-color-accent-12: #003575;
  --ds-color-accent-13: #001E42;
  --ds-color-accent-contrast-1: #ffffff;
  --ds-color-accent-contrast-2: #F5F9FF;
  --ds-color-accent-background-default: var(--ds-color-accent-1);
  --ds-color-accent-background-subtle: var(--ds-color-accent-2);
  --ds-color-accent-surface-default: var(--ds-color-accent-3);
  --ds-color-accent-surface-hover: var(--ds-color-accent-4);
  --ds-color-accent-surface-active: var(--ds-color-accent-5);
  --ds-color-accent-border-subtle: var(--ds-color-accent-6);
  --ds-color-accent-border-default: var(--ds-color-accent-7);
  --ds-color-accent-border-strong: var(--ds-color-accent-8);
  --ds-color-accent-base-default: var(--ds-color-accent-9);
  --ds-color-accent-base-hover: var(--ds-color-accent-10);
  --ds-color-accent-base-active: var(--ds-color-accent-11);
  --ds-color-accent-text-subtle: var(--ds-color-accent-12);
  --ds-color-accent-text-default: var(--ds-color-accent-13);
  --ds-color-accent-contrast-default: var(--ds-color-accent-contrast-1);
  --ds-color-accent-contrast-subtle: var(--ds-color-accent-contrast-2);
  --ds-color-neutral-1: #fefefe;
  --ds-color-neutral-2: #F7F8FA;
  --ds-color-neutral-3: #E7EAEF;
  --ds-color-neutral-4: #D1D6DB;
  --ds-color-neutral-5: #BBC1C9;
  --ds-color-neutral-6: #BBC1C9;
  --ds-color-neutral-7: #7E8995;
  --ds-color-neutral-8: #45505D;
  --ds-color-neutral-9: #45505D;
  --ds-color-neutral-10: #263240;
  --ds-color-neutral-11: #131E2B;
  --ds-color-neutral-12: #263240;
  --ds-color-neutral-13: #131E2B;
  --ds-color-neutral-contrast-1: #fff;
  --ds-color-neutral-contrast-2: #F7F8FA;
  --ds-color-neutral-background-default: var(--ds-color-neutral-1);
  --ds-color-neutral-background-subtle: var(--ds-color-neutral-2);
  --ds-color-neutral-surface-default: var(--ds-color-neutral-3);
  --ds-color-neutral-surface-hover: var(--ds-color-neutral-4);
  --ds-color-neutral-surface-active: var(--ds-color-neutral-5);
  --ds-color-neutral-border-subtle: var(--ds-color-neutral-6);
  --ds-color-neutral-border-default: var(--ds-color-neutral-7);
  --ds-color-neutral-border-strong: var(--ds-color-neutral-8);
  --ds-color-neutral-base-default: var(--ds-color-neutral-9);
  --ds-color-neutral-base-hover: var(--ds-color-neutral-10);
  --ds-color-neutral-base-active: var(--ds-color-neutral-11);
  --ds-color-neutral-text-subtle: var(--ds-color-neutral-12);
  --ds-color-neutral-text-default: var(--ds-color-neutral-13);
  --ds-color-neutral-contrast-default: var(--ds-color-neutral-contrast-1);
  --ds-color-neutral-contrast-subtle: var(--ds-color-neutral-contrast-2);
  --ds-color-brand1-1: #FEFEFE;
  --ds-color-brand1-2: #F5F9FF;
  --ds-color-brand1-3: #E0EEFF;
  --ds-color-brand1-4: #C2DDFF;
  --ds-color-brand1-5: #A3CDFF;
  --ds-color-brand1-6: #A3CDFF;
  --ds-color-brand1-7: #4791EB;
  --ds-color-brand1-8: #0053B8;
  --ds-color-brand1-9: #0053B8;
  --ds-color-brand1-10: #003575;
  --ds-color-brand1-11: #001E42;
  --ds-color-brand1-12: #003575;
  --ds-color-brand1-13: #001E42;
  --ds-color-brand1-contrast-1: #ffffff;
  --ds-color-brand1-contrast-2: #F5F9FF;
  --ds-color-brand1-background-default: var(--ds-color-brand1-1);
  --ds-color-brand1-background-subtle: var(--ds-color-brand1-2);
  --ds-color-brand1-surface-default: var(--ds-color-brand1-3);
  --ds-color-brand1-surface-hover: var(--ds-color-brand1-4);
  --ds-color-brand1-surface-active: var(--ds-color-brand1-5);
  --ds-color-brand1-border-subtle: var(--ds-color-brand1-6);
  --ds-color-brand1-border-default: var(--ds-color-brand1-7);
  --ds-color-brand1-border-strong: var(--ds-color-brand1-8);
  --ds-color-brand1-base-default: var(--ds-color-brand1-9);
  --ds-color-brand1-base-hover: var(--ds-color-brand1-10);
  --ds-color-brand1-base-active: var(--ds-color-brand1-11);
  --ds-color-brand1-text-subtle: var(--ds-color-brand1-12);
  --ds-color-brand1-text-default: var(--ds-color-brand1-13);
  --ds-color-brand1-contrast-default: var(--ds-color-brand1-contrast-1);
  --ds-color-brand1-contrast-subtle: var(--ds-color-brand1-contrast-2);
  --ds-color-brand2-1: #FEFEFE;
  --ds-color-brand2-2: #FFF7F0;
  --ds-color-brand2-3: #FFEBD6;
  --ds-color-brand2-4: #FFD4A8;
  --ds-color-brand2-5: #FFC285;
  --ds-color-brand2-6: #FFC285;
  --ds-color-brand2-7: #DC8228;
  --ds-color-brand2-8: #854200;
  --ds-color-brand2-9: #854200;
  --ds-color-brand2-10: #5C2E00;
  --ds-color-brand2-11: #331A00;
  --ds-color-brand2-12: #5C2E00;
  --ds-color-brand2-13: #331A00;
  --ds-color-brand2-contrast-1: #ffffff;
  --ds-color-brand2-contrast-2: #FFF7F0;
  --ds-color-brand2-background-default: var(--ds-color-brand2-1);
  --ds-color-brand2-background-subtle: var(--ds-color-brand2-2);
  --ds-color-brand2-surface-default: var(--ds-color-brand2-3);
  --ds-color-brand2-surface-hover: var(--ds-color-brand2-4);
  --ds-color-brand2-surface-active: var(--ds-color-brand2-5);
  --ds-color-brand2-border-subtle: var(--ds-color-brand2-6);
  --ds-color-brand2-border-default: var(--ds-color-brand2-7);
  --ds-color-brand2-border-strong: var(--ds-color-brand2-8);
  --ds-color-brand2-base-default: var(--ds-color-brand2-9);
  --ds-color-brand2-base-hover: var(--ds-color-brand2-10);
  --ds-color-brand2-base-active: var(--ds-color-brand2-11);
  --ds-color-brand2-text-subtle: var(--ds-color-brand2-12);
  --ds-color-brand2-text-default: var(--ds-color-brand2-13);
  --ds-color-brand2-contrast-default: var(--ds-color-brand2-contrast-1);
  --ds-color-brand2-contrast-subtle: var(--ds-color-brand2-contrast-2);
  --ds-color-brand3-1: #FDFCFF;
  --ds-color-brand3-2: #FAF5FF;
  --ds-color-brand3-3: #F2E5FF;
  --ds-color-brand3-4: #E3C7FF;
  --ds-color-brand3-5: #D4A8FF;
  --ds-color-brand3-6: #D4A8FF;
  --ds-color-brand3-7: #9952E0;
  --ds-color-brand3-8: #5C12A5;
  --ds-color-brand3-9: #5C12A5;
  --ds-color-brand3-10: #400A76;
  --ds-color-brand3-11: #21033F;
  --ds-color-brand3-12: #400A76;
  --ds-color-brand3-13: #21033F;
  --ds-color-brand3-contrast-1: #ffffff;
  --ds-color-brand3-contrast-2: #FAF5FF;
  --ds-color-brand3-background-default: var(--ds-color-brand3-1);
  --ds-color-brand3-background-subtle: var(--ds-color-brand3-2);
  --ds-color-brand3-surface-default: var(--ds-color-brand3-3);
  --ds-color-brand3-surface-hover: var(--ds-color-brand3-4);
  --ds-color-brand3-surface-active: var(--ds-color-brand3-5);
  --ds-color-brand3-border-subtle: var(--ds-color-brand3-6);
  --ds-color-brand3-border-default: var(--ds-color-brand3-7);
  --ds-color-brand3-border-strong: var(--ds-color-brand3-8);
  --ds-color-brand3-base-default: var(--ds-color-brand3-9);
  --ds-color-brand3-base-hover: var(--ds-color-brand3-10);
  --ds-color-brand3-base-active: var(--ds-color-brand3-11);
  --ds-color-brand3-text-subtle: var(--ds-color-brand3-12);
  --ds-color-brand3-text-default: var(--ds-color-brand3-13);
  --ds-color-brand3-contrast-default: var(--ds-color-brand3-contrast-1);
  --ds-color-brand3-contrast-subtle: var(--ds-color-brand3-contrast-2);
  --ds-color-success-background-default: var(--ds-global-green-1);
  --ds-color-success-background-subtle: var(--ds-global-green-2);
  --ds-color-success-surface-default: var(--ds-global-green-3);
  --ds-color-success-surface-hover: var(--ds-global-green-4);
  --ds-color-success-surface-active: var(--ds-global-green-5);
  --ds-color-success-border-subtle: var(--ds-global-green-6);
  --ds-color-success-border-default: var(--ds-global-green-7);
  --ds-color-success-border-strong: var(--ds-global-green-8);
  --ds-color-success-base-default: var(--ds-global-green-9);
  --ds-color-success-base-hover: var(--ds-global-green-10);
  --ds-color-success-base-active: var(--ds-global-green-11);
  --ds-color-success-text-subtle: var(--ds-global-green-12);
  --ds-color-success-text-default: var(--ds-global-green-13);
  --ds-color-success-contrast-default: var(--ds-global-green-contrast-1);
  --ds-color-success-contrast-subtle: var(--ds-global-green-contrast-2);
  --ds-color-danger-background-default: var(--ds-global-red-1);
  --ds-color-danger-background-subtle: var(--ds-global-red-2);
  --ds-color-danger-surface-default: var(--ds-global-red-3);
  --ds-color-danger-surface-hover: var(--ds-global-red-4);
  --ds-color-danger-surface-active: var(--ds-global-red-5);
  --ds-color-danger-border-subtle: var(--ds-global-red-6);
  --ds-color-danger-border-default: var(--ds-global-red-7);
  --ds-color-danger-border-strong: var(--ds-global-red-8);
  --ds-color-danger-base-default: var(--ds-global-red-9);
  --ds-color-danger-base-hover: var(--ds-global-red-10);
  --ds-color-danger-base-active: var(--ds-global-red-11);
  --ds-color-danger-text-subtle: var(--ds-global-red-12);
  --ds-color-danger-text-default: var(--ds-global-red-13);
  --ds-color-danger-contrast-default: var(--ds-global-red-contrast-1);
  --ds-color-danger-contrast-subtle: var(--ds-global-red-contrast-2);
  --ds-color-info-background-default: #fefeff;
  --ds-color-info-background-subtle: #edf5fa;
  --ds-color-info-surface-default: #d3e5f4;
  --ds-color-info-surface-hover: #b7d5ed;
  --ds-color-info-surface-active: #9bc5e5;
  --ds-color-info-border-subtle: #94c1e3;
  --ds-color-info-border-default: #2f86c9;
  --ds-color-info-border-strong: #075089;
  --ds-color-info-base-default: #0A71C0;
  --ds-color-info-base-hover: #085ea0;
  --ds-color-info-base-active: #074b80;
  --ds-color-info-text-subtle: #0966ac;
  --ds-color-info-text-default: #042d4d;
  --ds-color-info-contrast-default: #ffffff;
  --ds-color-info-contrast-subtle: #f3f8fc;
  --ds-color-warning-background-default: var(--ds-global-yellow-1);
  --ds-color-warning-background-subtle: var(--ds-global-yellow-2);
  --ds-color-warning-surface-default: var(--ds-global-yellow-3);
  --ds-color-warning-surface-hover: var(--ds-global-yellow-4);
  --ds-color-warning-surface-active: var(--ds-global-yellow-5);
  --ds-color-warning-border-subtle: var(--ds-global-yellow-6);
  --ds-color-warning-border-default: var(--ds-global-yellow-7);
  --ds-color-warning-border-strong: var(--ds-global-yellow-8);
  --ds-color-warning-base-default: var(--ds-global-orange-9);
  --ds-color-warning-base-hover: var(--ds-global-orange-10);
  --ds-color-warning-base-active: var(--ds-global-orange-11);
  --ds-color-warning-text-subtle: var(--ds-global-orange-12);
  --ds-color-warning-text-default: var(--ds-global-orange-13);
  --ds-color-warning-contrast-default: var(--ds-global-yellow-contrast-1);
  --ds-color-warning-contrast-subtle: var(--ds-global-yellow-contrast-2);
  --ds-color-focus-inner: #ECC238;
  --ds-color-focus-outer: #131E2B;
color-scheme: light;
}
 
@media (prefers-color-scheme: light) {
  [data-ds-color-mode="auto"] {
  --ds-global-blue-1: #fefeff;
  --ds-global-blue-2: #edf5fa;
  --ds-global-blue-3: #d3e5f4;
  --ds-global-blue-4: #b7d5ed;
  --ds-global-blue-5: #9bc5e5;
  --ds-global-blue-6: #94c1e3;
  --ds-global-blue-7: #2f86c9;
  --ds-global-blue-8: #075089;
  --ds-global-blue-9: #0A71C0;
  --ds-global-blue-10: #085ea0;
  --ds-global-blue-11: #074b80;
  --ds-global-blue-12: #0966ac;
  --ds-global-blue-13: #042d4d;
  --ds-global-blue-contrast-1: #ffffff;
  --ds-global-blue-contrast-2: #f3f8fc;
  --ds-global-green-1: #fcfefc;
  --ds-global-green-2: #ecf6ed;
  --ds-global-green-3: #cfe9d3;
  --ds-global-green-4: #b3dcb8;
  --ds-global-green-5: #95ce9d;
  --ds-global-green-6: #8bca94;
  --ds-global-green-7: #189528;
  --ds-global-green-8: #045a10;
  --ds-global-green-9: #078D19;
  --ds-global-green-10: #067615;
  --ds-global-green-11: #056011;
  --ds-global-green-12: #067314;
  --ds-global-green-13: #033409;
  --ds-global-green-contrast-1: #000000;
  --ds-global-green-contrast-2: #000401;
  --ds-global-orange-1: #fffefd;
  --ds-global-orange-2: #fbf1ec;
  --ds-global-orange-3: #f4ddd0;
  --ds-global-orange-4: #eecab7;
  --ds-global-orange-5: #e7b69c;
  --ds-global-orange-6: #e5b094;
  --ds-global-orange-7: #cc632b;
  --ds-global-orange-8: #7e3a15;
  --ds-global-orange-9: #CA5C21;
  --ds-global-orange-10: #a94d1c;
  --ds-global-orange-11: #8a3f17;
  --ds-global-orange-12: #a1491a;
  --ds-global-orange-13: #47210c;
  --ds-global-orange-contrast-1: #000000;
  --ds-global-orange-contrast-2: #0d0602;
  --ds-global-purple-1: #fefefe;
  --ds-global-purple-2: #f5f2f9;
  --ds-global-purple-3: #e7dfef;
  --ds-global-purple-4: #d9cce6;
  --ds-global-purple-5: #ccbadd;
  --ds-global-purple-6: #c7b4da;
  --ds-global-purple-7: #9572b9;
  --ds-global-purple-8: #663399;
  --ds-global-purple-9: #663299;
  --ds-global-purple-10: #4f2777;
  --ds-global-purple-11: #381b54;
  --ds-global-purple-12: #7b4ea7;
  --ds-global-purple-13: #3b1d59;
  --ds-global-purple-contrast-1: #ffffff;
  --ds-global-purple-contrast-2: #eee8f3;
  --ds-global-red-1: #fffefe;
  --ds-global-red-2: #fbf1f1;
  --ds-global-red-3: #f5dcdc;
  --ds-global-red-4: #f0c7c7;
  --ds-global-red-5: #eab3b3;
  --ds-global-red-6: #e8acac;
  --ds-global-red-7: #d25b5b;
  --ds-global-red-8: #9a1616;
  --ds-global-red-9: #C01B1B;
  --ds-global-red-10: #9a1616;
  --ds-global-red-11: #771111;
  --ds-global-red-12: #c22020;
  --ds-global-red-13: #5a0d0d;
  --ds-global-red-contrast-1: #ffffff;
  --ds-global-red-contrast-2: #f6dfdf;
  --ds-global-yellow-1: #fffefc;
  --ds-global-yellow-2: #fbf2d3;
  --ds-global-yellow-3: #f5e19b;
  --ds-global-yellow-4: #efcf5d;
  --ds-global-yellow-5: #e6bc27;
  --ds-global-yellow-6: #e0b726;
  --ds-global-yellow-7: #9a7e1a;
  --ds-global-yellow-8: #5d4c10;
  --ds-global-yellow-9: #EABF28;
  --ds-global-yellow-10: #d0aa24;
  --ds-global-yellow-11: #b7951f;
  --ds-global-yellow-12: #776114;
  --ds-global-yellow-13: #352b09;
  --ds-global-yellow-contrast-1: #000000;
  --ds-global-yellow-contrast-2: #382d0a;
  --ds-color-accent-1: #FEFEFE;
  --ds-color-accent-2: #F5F9FF;
  --ds-color-accent-3: #E0EEFF;
  --ds-color-accent-4: #C2DDFF;
  --ds-color-accent-5: #A3CDFF;
  --ds-color-accent-6: #A3CDFF;
  --ds-color-accent-7: #4791EB;
  --ds-color-accent-8: #0053B8;
  --ds-color-accent-9: #0053B8;
  --ds-color-accent-10: #003575;
  --ds-color-accent-11: #001E42;
  --ds-color-accent-12: #003575;
  --ds-color-accent-13: #001E42;
  --ds-color-accent-contrast-1: #ffffff;
  --ds-color-accent-contrast-2: #F5F9FF;
  --ds-color-accent-background-default: var(--ds-color-accent-1);
  --ds-color-accent-background-subtle: var(--ds-color-accent-2);
  --ds-color-accent-surface-default: var(--ds-color-accent-3);
  --ds-color-accent-surface-hover: var(--ds-color-accent-4);
  --ds-color-accent-surface-active: var(--ds-color-accent-5);
  --ds-color-accent-border-subtle: var(--ds-color-accent-6);
  --ds-color-accent-border-default: var(--ds-color-accent-7);
  --ds-color-accent-border-strong: var(--ds-color-accent-8);
  --ds-color-accent-base-default: var(--ds-color-accent-9);
  --ds-color-accent-base-hover: var(--ds-color-accent-10);
  --ds-color-accent-base-active: var(--ds-color-accent-11);
  --ds-color-accent-text-subtle: var(--ds-color-accent-12);
  --ds-color-accent-text-default: var(--ds-color-accent-13);
  --ds-color-accent-contrast-default: var(--ds-color-accent-contrast-1);
  --ds-color-accent-contrast-subtle: var(--ds-color-accent-contrast-2);
  --ds-color-neutral-1: #fefefe;
  --ds-color-neutral-2: #F7F8FA;
  --ds-color-neutral-3: #E7EAEF;
  --ds-color-neutral-4: #D1D6DB;
  --ds-color-neutral-5: #BBC1C9;
  --ds-color-neutral-6: #BBC1C9;
  --ds-color-neutral-7: #7E8995;
  --ds-color-neutral-8: #45505D;
  --ds-color-neutral-9: #45505D;
  --ds-color-neutral-10: #263240;
  --ds-color-neutral-11: #131E2B;
  --ds-color-neutral-12: #263240;
  --ds-color-neutral-13: #131E2B;
  --ds-color-neutral-contrast-1: #fff;
  --ds-color-neutral-contrast-2: #F7F8FA;
  --ds-color-neutral-background-default: var(--ds-color-neutral-1);
  --ds-color-neutral-background-subtle: var(--ds-color-neutral-2);
  --ds-color-neutral-surface-default: var(--ds-color-neutral-3);
  --ds-color-neutral-surface-hover: var(--ds-color-neutral-4);
  --ds-color-neutral-surface-active: var(--ds-color-neutral-5);
  --ds-color-neutral-border-subtle: var(--ds-color-neutral-6);
  --ds-color-neutral-border-default: var(--ds-color-neutral-7);
  --ds-color-neutral-border-strong: var(--ds-color-neutral-8);
  --ds-color-neutral-base-default: var(--ds-color-neutral-9);
  --ds-color-neutral-base-hover: var(--ds-color-neutral-10);
  --ds-color-neutral-base-active: var(--ds-color-neutral-11);
  --ds-color-neutral-text-subtle: var(--ds-color-neutral-12);
  --ds-color-neutral-text-default: var(--ds-color-neutral-13);
  --ds-color-neutral-contrast-default: var(--ds-color-neutral-contrast-1);
  --ds-color-neutral-contrast-subtle: var(--ds-color-neutral-contrast-2);
  --ds-color-brand1-1: #FEFEFE;
  --ds-color-brand1-2: #F5F9FF;
  --ds-color-brand1-3: #E0EEFF;
  --ds-color-brand1-4: #C2DDFF;
  --ds-color-brand1-5: #A3CDFF;
  --ds-color-brand1-6: #A3CDFF;
  --ds-color-brand1-7: #4791EB;
  --ds-color-brand1-8: #0053B8;
  --ds-color-brand1-9: #0053B8;
  --ds-color-brand1-10: #003575;
  --ds-color-brand1-11: #001E42;
  --ds-color-brand1-12: #003575;
  --ds-color-brand1-13: #001E42;
  --ds-color-brand1-contrast-1: #ffffff;
  --ds-color-brand1-contrast-2: #F5F9FF;
  --ds-color-brand1-background-default: var(--ds-color-brand1-1);
  --ds-color-brand1-background-subtle: var(--ds-color-brand1-2);
  --ds-color-brand1-surface-default: var(--ds-color-brand1-3);
  --ds-color-brand1-surface-hover: var(--ds-color-brand1-4);
  --ds-color-brand1-surface-active: var(--ds-color-brand1-5);
  --ds-color-brand1-border-subtle: var(--ds-color-brand1-6);
  --ds-color-brand1-border-default: var(--ds-color-brand1-7);
  --ds-color-brand1-border-strong: var(--ds-color-brand1-8);
  --ds-color-brand1-base-default: var(--ds-color-brand1-9);
  --ds-color-brand1-base-hover: var(--ds-color-brand1-10);
  --ds-color-brand1-base-active: var(--ds-color-brand1-11);
  --ds-color-brand1-text-subtle: var(--ds-color-brand1-12);
  --ds-color-brand1-text-default: var(--ds-color-brand1-13);
  --ds-color-brand1-contrast-default: var(--ds-color-brand1-contrast-1);
  --ds-color-brand1-contrast-subtle: var(--ds-color-brand1-contrast-2);
  --ds-color-brand2-1: #FEFEFE;
  --ds-color-brand2-2: #FFF7F0;
  --ds-color-brand2-3: #FFEBD6;
  --ds-color-brand2-4: #FFD4A8;
  --ds-color-brand2-5: #FFC285;
  --ds-color-brand2-6: #FFC285;
  --ds-color-brand2-7: #DC8228;
  --ds-color-brand2-8: #854200;
  --ds-color-brand2-9: #854200;
  --ds-color-brand2-10: #5C2E00;
  --ds-color-brand2-11: #331A00;
  --ds-color-brand2-12: #5C2E00;
  --ds-color-brand2-13: #331A00;
  --ds-color-brand2-contrast-1: #ffffff;
  --ds-color-brand2-contrast-2: #FFF7F0;
  --ds-color-brand2-background-default: var(--ds-color-brand2-1);
  --ds-color-brand2-background-subtle: var(--ds-color-brand2-2);
  --ds-color-brand2-surface-default: var(--ds-color-brand2-3);
  --ds-color-brand2-surface-hover: var(--ds-color-brand2-4);
  --ds-color-brand2-surface-active: var(--ds-color-brand2-5);
  --ds-color-brand2-border-subtle: var(--ds-color-brand2-6);
  --ds-color-brand2-border-default: var(--ds-color-brand2-7);
  --ds-color-brand2-border-strong: var(--ds-color-brand2-8);
  --ds-color-brand2-base-default: var(--ds-color-brand2-9);
  --ds-color-brand2-base-hover: var(--ds-color-brand2-10);
  --ds-color-brand2-base-active: var(--ds-color-brand2-11);
  --ds-color-brand2-text-subtle: var(--ds-color-brand2-12);
  --ds-color-brand2-text-default: var(--ds-color-brand2-13);
  --ds-color-brand2-contrast-default: var(--ds-color-brand2-contrast-1);
  --ds-color-brand2-contrast-subtle: var(--ds-color-brand2-contrast-2);
  --ds-color-brand3-1: #FDFCFF;
  --ds-color-brand3-2: #FAF5FF;
  --ds-color-brand3-3: #F2E5FF;
  --ds-color-brand3-4: #E3C7FF;
  --ds-color-brand3-5: #D4A8FF;
  --ds-color-brand3-6: #D4A8FF;
  --ds-color-brand3-7: #9952E0;
  --ds-color-brand3-8: #5C12A5;
  --ds-color-brand3-9: #5C12A5;
  --ds-color-brand3-10: #400A76;
  --ds-color-brand3-11: #21033F;
  --ds-color-brand3-12: #400A76;
  --ds-color-brand3-13: #21033F;
  --ds-color-brand3-contrast-1: #ffffff;
  --ds-color-brand3-contrast-2: #FAF5FF;
  --ds-color-brand3-background-default: var(--ds-color-brand3-1);
  --ds-color-brand3-background-subtle: var(--ds-color-brand3-2);
  --ds-color-brand3-surface-default: var(--ds-color-brand3-3);
  --ds-color-brand3-surface-hover: var(--ds-color-brand3-4);
  --ds-color-brand3-surface-active: var(--ds-color-brand3-5);
  --ds-color-brand3-border-subtle: var(--ds-color-brand3-6);
  --ds-color-brand3-border-default: var(--ds-color-brand3-7);
  --ds-color-brand3-border-strong: var(--ds-color-brand3-8);
  --ds-color-brand3-base-default: var(--ds-color-brand3-9);
  --ds-color-brand3-base-hover: var(--ds-color-brand3-10);
  --ds-color-brand3-base-active: var(--ds-color-brand3-11);
  --ds-color-brand3-text-subtle: var(--ds-color-brand3-12);
  --ds-color-brand3-text-default: var(--ds-color-brand3-13);
  --ds-color-brand3-contrast-default: var(--ds-color-brand3-contrast-1);
  --ds-color-brand3-contrast-subtle: var(--ds-color-brand3-contrast-2);
  --ds-color-success-background-default: var(--ds-global-green-1);
  --ds-color-success-background-subtle: var(--ds-global-green-2);
  --ds-color-success-surface-default: var(--ds-global-green-3);
  --ds-color-success-surface-hover: var(--ds-global-green-4);
  --ds-color-success-surface-active: var(--ds-global-green-5);
  --ds-color-success-border-subtle: var(--ds-global-green-6);
  --ds-color-success-border-default: var(--ds-global-green-7);
  --ds-color-success-border-strong: var(--ds-global-green-8);
  --ds-color-success-base-default: var(--ds-global-green-9);
  --ds-color-success-base-hover: var(--ds-global-green-10);
  --ds-color-success-base-active: var(--ds-global-green-11);
  --ds-color-success-text-subtle: var(--ds-global-green-12);
  --ds-color-success-text-default: var(--ds-global-green-13);
  --ds-color-success-contrast-default: var(--ds-global-green-contrast-1);
  --ds-color-success-contrast-subtle: var(--ds-global-green-contrast-2);
  --ds-color-danger-background-default: var(--ds-global-red-1);
  --ds-color-danger-background-subtle: var(--ds-global-red-2);
  --ds-color-danger-surface-default: var(--ds-global-red-3);
  --ds-color-danger-surface-hover: var(--ds-global-red-4);
  --ds-color-danger-surface-active: var(--ds-global-red-5);
  --ds-color-danger-border-subtle: var(--ds-global-red-6);
  --ds-color-danger-border-default: var(--ds-global-red-7);
  --ds-color-danger-border-strong: var(--ds-global-red-8);
  --ds-color-danger-base-default: var(--ds-global-red-9);
  --ds-color-danger-base-hover: var(--ds-global-red-10);
  --ds-color-danger-base-active: var(--ds-global-red-11);
  --ds-color-danger-text-subtle: var(--ds-global-red-12);
  --ds-color-danger-text-default: var(--ds-global-red-13);
  --ds-color-danger-contrast-default: var(--ds-global-red-contrast-1);
  --ds-color-danger-contrast-subtle: var(--ds-global-red-contrast-2);
  --ds-color-info-background-default: #fefeff;
  --ds-color-info-background-subtle: #edf5fa;
  --ds-color-info-surface-default: #d3e5f4;
  --ds-color-info-surface-hover: #b7d5ed;
  --ds-color-info-surface-active: #9bc5e5;
  --ds-color-info-border-subtle: #94c1e3;
  --ds-color-info-border-default: #2f86c9;
  --ds-color-info-border-strong: #075089;
  --ds-color-info-base-default: #0A71C0;
  --ds-color-info-base-hover: #085ea0;
  --ds-color-info-base-active: #074b80;
  --ds-color-info-text-subtle: #0966ac;
  --ds-color-info-text-default: #042d4d;
  --ds-color-info-contrast-default: #ffffff;
  --ds-color-info-contrast-subtle: #f3f8fc;
  --ds-color-warning-background-default: var(--ds-global-yellow-1);
  --ds-color-warning-background-subtle: var(--ds-global-yellow-2);
  --ds-color-warning-surface-default: var(--ds-global-yellow-3);
  --ds-color-warning-surface-hover: var(--ds-global-yellow-4);
  --ds-color-warning-surface-active: var(--ds-global-yellow-5);
  --ds-color-warning-border-subtle: var(--ds-global-yellow-6);
  --ds-color-warning-border-default: var(--ds-global-yellow-7);
  --ds-color-warning-border-strong: var(--ds-global-yellow-8);
  --ds-color-warning-base-default: var(--ds-global-orange-9);
  --ds-color-warning-base-hover: var(--ds-global-orange-10);
  --ds-color-warning-base-active: var(--ds-global-orange-11);
  --ds-color-warning-text-subtle: var(--ds-global-orange-12);
  --ds-color-warning-text-default: var(--ds-global-orange-13);
  --ds-color-warning-contrast-default: var(--ds-global-yellow-contrast-1);
  --ds-color-warning-contrast-subtle: var(--ds-global-yellow-contrast-2);
  --ds-color-focus-inner: #ECC238;
  --ds-color-focus-outer: #131E2B;
color-scheme: light;
}

}

}

/**
 * These files are generated from design tokens defind using Token Studio
 */

@layer ds.theme.typography.secondary {
[data-ds-typography="secondary"] {
  --ds-font-family: IBM Plex Mono;
  --ds-font-weight-medium: 500;
  --ds-font-weight-semibold: 600;
  --ds-font-weight-regular: 400;
  --ds-line-height-sm: 1.3;
  --ds-line-height-md: 1.5;
  --ds-line-height-lg: 1.7;
  --ds-font-size-1: 0.75rem;
  --ds-font-size-2: 0.8125rem;
  --ds-font-size-3: 0.875rem;
  --ds-font-size-4: 1rem;
  --ds-font-size-5: 1.125rem;
  --ds-font-size-6: 1.3125rem;
  --ds-font-size-7: 1.5rem;
  --ds-font-size-8: 1.875rem;
  --ds-font-size-9: 2.25rem;
  --ds-font-size-10: 3rem;
  --ds-font-size-11: 3.75rem;
  --ds-letter-spacing-1: -1%;
  --ds-letter-spacing-2: -0.5%;
  --ds-letter-spacing-3: -0.25%;
  --ds-letter-spacing-4: -0.15%;
  --ds-letter-spacing-5: 0%;
  --ds-letter-spacing-6: 0.15%;
  --ds-letter-spacing-7: 0.25%;
  --ds-letter-spacing-8: 0.5%;
  --ds-letter-spacing-9: 1.5%;

  .ds-error_message--xs {
    font-size: var(--ds-font-size-3);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-error_message--sm {
    font-size: var(--ds-font-size-4);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-error_message--md {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-error_message--lg {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-label--xs {
    font-size: var(--ds-font-size-3);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-label--sm {
    font-size: var(--ds-font-size-4);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-label--md {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-label--lg {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph-long--xs {
    font-size: var(--ds-font-size-3);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-paragraph-long--sm {
    font-size: var(--ds-font-size-4);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-paragraph-long--md {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph-long--lg {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph-short--xs {
    font-size: var(--ds-font-size-3);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-paragraph-short--sm {
    font-size: var(--ds-font-size-4);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-paragraph-short--md {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph-short--lg {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph--xs {
    font-size: var(--ds-font-size-3);
    line-height: var(--ds-line-height-md);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-paragraph--sm {
    font-size: var(--ds-font-size-4);
    line-height: var(--ds-line-height-md);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-paragraph--md {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-md);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph--lg {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-md);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-ingress--xs {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-ingress--sm {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-ingress--md {
    font-size: var(--ds-font-size-7);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-ingress--lg {
    font-size: var(--ds-font-size-8);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-heading--2xs {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-heading--xs {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-heading--sm {
    font-size: var(--ds-font-size-7);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-5);
  }

  .ds-heading--md {
    font-size: var(--ds-font-size-8);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-3);
  }

  .ds-heading--lg {
    font-size: var(--ds-font-size-9);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-2);
  }

  .ds-heading--xl {
    font-size: var(--ds-font-size-10);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-1);
  }

  .ds-heading--2xl {
    font-size: var(--ds-font-size-11);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-1);
  }

  .ds-font-weight--medium {
    font-weight: 500;
  }

  .ds-font-weight--semibold {
    font-weight: 600;
  }

  .ds-font-weight--regular {
    font-weight: 400;
  }

  .ds-line-height--sm {
    line-height: 1.3;
  }

  .ds-line-height--md {
    line-height: 1.5;
  }

  .ds-line-height--lg {
    line-height: 1.7;
  }
}
}

/**
 * These files are generated from design tokens defind using Token Studio
 */

@layer ds.theme.semantic {
:root {
  --ds-typography-heading-2xl: 500 3.75rem/1.3 'Inter';
  --ds-typography-heading-xl: 500 3rem/1.3 'Inter';
  --ds-typography-heading-lg: 500 2.25rem/1.3 'Inter';
  --ds-typography-heading-md: 500 1.875rem/1.3 'Inter';
  --ds-typography-heading-sm: 500 1.5rem/1.3 'Inter';
  --ds-typography-heading-xs: 500 1.3125rem/1.3 'Inter';
  --ds-typography-heading-2xs: 500 1.125rem/1.3 'Inter';
  --ds-typography-ingress-lg: 400 1.875rem/1.7 'Inter';
  --ds-typography-ingress-md: 400 1.5rem/1.7 'Inter';
  --ds-typography-ingress-sm: 400 1.3125rem/1.7 'Inter';
  --ds-typography-ingress-xs: 400 1.125rem/1.7 'Inter';
  --ds-typography-paragraph-lg: 400 1.3125rem/1.5 'Inter';
  --ds-typography-paragraph-md: 400 1.125rem/1.5 'Inter';
  --ds-typography-paragraph-sm: 400 1rem/1.5 'Inter';
  --ds-typography-paragraph-xs: 400 0.875rem/1.5 'Inter';
  --ds-typography-paragraph-short-lg: 400 1.3125rem/1.3 'Inter';
  --ds-typography-paragraph-short-md: 400 1.125rem/1.3 'Inter';
  --ds-typography-paragraph-short-sm: 400 1rem/1.3 'Inter';
  --ds-typography-paragraph-short-xs: 400 0.875rem/1.3 'Inter';
  --ds-typography-paragraph-long-lg: 400 1.3125rem/1.7 'Inter';
  --ds-typography-paragraph-long-md: 400 1.125rem/1.7 'Inter';
  --ds-typography-paragraph-long-sm: 400 1rem/1.7 'Inter';
  --ds-typography-paragraph-long-xs: 400 0.875rem/1.7 'Inter';
  --ds-typography-label-lg: 500 1.3125rem/1.3 'Inter';
  --ds-typography-label-md: 500 1.125rem/1.3 'Inter';
  --ds-typography-label-sm: 500 1rem/1.3 'Inter';
  --ds-typography-label-xs: 500 0.875rem/1.3 'Inter';
  --ds-typography-error_message-lg: 400 1.3125rem/1.3 'Inter';
  --ds-typography-error_message-md: 400 1.125rem/1.3 'Inter';
  --ds-typography-error_message-sm: 400 1rem/1.3 'Inter';
  --ds-typography-error_message-xs: 400 0.875rem/1.3 'Inter';
  --ds-disabled-opacity: 30%;
  --ds-border-radius-sm: calc(var(--ds-border-radius-base)*0.5);
  --ds-border-radius-md: calc(var(--ds-border-radius-base)*1);
  --ds-border-radius-lg: calc(var(--ds-border-radius-base)*2);
  --ds-border-radius-xl: calc(var(--ds-border-radius-base)*3);
  --ds-border-radius-2xl: calc(var(--ds-border-radius-base)*4);
  --ds-border-radius-3xl: calc(var(--ds-border-radius-base)*6);
  --ds-border-radius-4xl: calc(var(--ds-border-radius-base)*8);
  --ds-border-radius-full: 624.9375rem;
  --ds-border-radius-base: 0.25rem;
  --ds-spacing-0: calc(var(--ds-spacing-base)*0);
  --ds-spacing-1: calc(var(--ds-spacing-base)*1);
  --ds-spacing-2: calc(var(--ds-spacing-base)*2);
  --ds-spacing-3: calc(var(--ds-spacing-base)*3);
  --ds-spacing-4: calc(var(--ds-spacing-base)*4);
  --ds-spacing-5: calc(var(--ds-spacing-base)*5);
  --ds-spacing-6: calc(var(--ds-spacing-base)*6);
  --ds-spacing-7: calc(var(--ds-spacing-base)*7);
  --ds-spacing-8: calc(var(--ds-spacing-base)*8);
  --ds-spacing-9: calc(var(--ds-spacing-base)*9);
  --ds-spacing-10: calc(var(--ds-spacing-base)*10);
  --ds-spacing-11: calc(var(--ds-spacing-base)*11);
  --ds-spacing-12: calc(var(--ds-spacing-base)*12);
  --ds-spacing-13: calc(var(--ds-spacing-base)*13);
  --ds-spacing-14: calc(var(--ds-spacing-base)*14);
  --ds-spacing-15: calc(var(--ds-spacing-base)*15);
  --ds-spacing-18: calc(var(--ds-spacing-base)*18);
  --ds-spacing-22: calc(var(--ds-spacing-base)*22);
  --ds-spacing-26: calc(var(--ds-spacing-base)*26);
  --ds-spacing-30: calc(var(--ds-spacing-base)*30);
  --ds-spacing-base: 0.25rem;
  --ds-sizing-0: calc(var(--ds-sizing-base)*0);
  --ds-sizing-1: calc(var(--ds-sizing-base)*1);
  --ds-sizing-2: calc(var(--ds-sizing-base)*2);
  --ds-sizing-3: calc(var(--ds-sizing-base)*3);
  --ds-sizing-4: calc(var(--ds-sizing-base)*4);
  --ds-sizing-5: calc(var(--ds-sizing-base)*5);
  --ds-sizing-6: calc(var(--ds-sizing-base)*6);
  --ds-sizing-7: calc(var(--ds-sizing-base)*7);
  --ds-sizing-8: calc(var(--ds-sizing-base)*8);
  --ds-sizing-9: calc(var(--ds-sizing-base)*9);
  --ds-sizing-10: calc(var(--ds-sizing-base)*10);
  --ds-sizing-11: calc(var(--ds-sizing-base)*11);
  --ds-sizing-12: calc(var(--ds-sizing-base)*12);
  --ds-sizing-13: calc(var(--ds-sizing-base)*13);
  --ds-sizing-14: calc(var(--ds-sizing-base)*14);
  --ds-sizing-15: calc(var(--ds-sizing-base)*15);
  --ds-sizing-18: calc(var(--ds-sizing-base)*18);
  --ds-sizing-22: calc(var(--ds-sizing-base)*22);
  --ds-sizing-26: calc(var(--ds-sizing-base)*26);
  --ds-sizing-30: calc(var(--ds-sizing-base)*30);
  --ds-sizing-base: 0.25rem;
  --ds-border-width-default: 1px;
  --ds-border-width-highlight: 2px;
  --ds-shadow-xs: 0 0 1px 0 rgba(0,0,0,0.16), 0 1px 2px 0 rgba(0,0,0,0.12);
  --ds-shadow-sm: 0 0 1px 0 rgba(0,0,0,0.15), 0 1px 2px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.1);
  --ds-shadow-md: 0 0 1px 0 rgba(0,0,0,0.14), 0 2px 4px 0 rgba(0,0,0,0.12), 0 4px 8px 0 rgba(0,0,0,0.12);
  --ds-shadow-lg: 0 0 1px 0 rgba(0,0,0,0.13), 0 3px 5px 0 rgba(0,0,0,0.13), 0 6px 12px 0 rgba(0,0,0,0.14);
  --ds-shadow-xl: 0 0 1px 0 rgba(0,0,0,0.12), 0 4px 8px 0 rgba(0,0,0,0.16), 0 12px 24px 0 rgba(0,0,0,0.16);
}

}

/**
 * These files are generated from design tokens defind using Token Studio
 */

@layer ds.theme.color-mode.dark {
[data-ds-color-mode="dark"] {
  --ds-global-blue-1: #031d30;
  --ds-global-blue-2: #032540;
  --ds-global-blue-3: #043256;
  --ds-global-blue-4: #063f6c;
  --ds-global-blue-5: #074d84;
  --ds-global-blue-6: #075490;
  --ds-global-blue-7: #0c72c1;
  --ds-global-blue-8: #98c3e5;
  --ds-global-blue-9: #2a84c8;
  --ds-global-blue-10: #0a6dba;
  --ds-global-blue-11: #085998;
  --ds-global-blue-12: #6ba9d9;
  --ds-global-blue-13: #d3e5f4;
  --ds-global-blue-contrast-1: #000000;
  --ds-global-blue-contrast-2: #040c13;
  --ds-global-green-1: #022106;
  --ds-global-green-2: #022b08;
  --ds-global-green-3: #03380a;
  --ds-global-green-4: #04480d;
  --ds-global-green-5: #045810;
  --ds-global-green-6: #056011;
  --ds-global-green-7: #068117;
  --ds-global-green-8: #90cc98;
  --ds-global-green-9: #078818;
  --ds-global-green-10: #067114;
  --ds-global-green-11: #045a10;
  --ds-global-green-12: #5fb56b;
  --ds-global-green-13: #cfe9d3;
  --ds-global-green-contrast-1: #ffffff;
  --ds-global-green-contrast-2: #f9fcf9;
  --ds-global-orange-1: #2e1508;
  --ds-global-orange-2: #3a1b0a;
  --ds-global-orange-3: #50240d;
  --ds-global-orange-4: #652e10;
  --ds-global-orange-5: #7a3814;
  --ds-global-orange-6: #863d16;
  --ds-global-orange-7: #b5531e;
  --ds-global-orange-8: #e6b398;
  --ds-global-orange-9: #ba541e;
  --ds-global-orange-10: #994619;
  --ds-global-orange-11: #7a3814;
  --ds-global-orange-12: #db9169;
  --ds-global-orange-13: #f4ddd0;
  --ds-global-orange-contrast-1: #ffffff;
  --ds-global-orange-contrast-2: #fcf8f6;
  --ds-global-purple-1: #251238;
  --ds-global-purple-2: #301748;
  --ds-global-purple-3: #402060;
  --ds-global-purple-4: #52287a;
  --ds-global-purple-5: #643196;
  --ds-global-purple-6: #6b399d;
  --ds-global-purple-7: #865daf;
  --ds-global-purple-8: #c9b7db;
  --ds-global-purple-9: #b298cc;
  --ds-global-purple-10: #a181c0;
  --ds-global-purple-11: #8f69b5;
  --ds-global-purple-12: #b298cc;
  --ds-global-purple-13: #e7dfef;
  --ds-global-purple-contrast-1: #000000;
  --ds-global-purple-contrast-2: #18141b;
  --ds-global-red-1: #3b0808;
  --ds-global-red-2: #4b0a0a;
  --ds-global-red-3: #620e0e;
  --ds-global-red-4: #7c1212;
  --ds-global-red-5: #961515;
  --ds-global-red-6: #a31717;
  --ds-global-red-7: #c93c3c;
  --ds-global-red-8: #e9b0b0;
  --ds-global-red-9: #d76e6e;
  --ds-global-red-10: #ce4e4e;
  --ds-global-red-11: #c32727;
  --ds-global-red-12: #df8d8d;
  --ds-global-red-13: #f5dcdc;
  --ds-global-red-contrast-1: #000000;
  --ds-global-red-contrast-2: #271414;
  --ds-global-yellow-1: #221b06;
  --ds-global-yellow-2: #2b2307;
  --ds-global-yellow-3: #3a300a;
  --ds-global-yellow-4: #4a3d0d;
  --ds-global-yellow-5: #5a4a0f;
  --ds-global-yellow-6: #635011;
  --ds-global-yellow-7: #856d17;
  --ds-global-yellow-8: #e4ba27;
  --ds-global-yellow-9: #3d310a;
  --ds-global-yellow-10: #52430e;
  --ds-global-yellow-11: #695512;
  --ds-global-yellow-12: #c39f21;
  --ds-global-yellow-13: #f5e19b;
  --ds-global-yellow-contrast-1: #ffffff;
  --ds-global-yellow-contrast-2: #ccc9bf;
  --ds-color-accent-1: #0C2038;
  --ds-color-accent-2: #102D4F;
  --ds-color-accent-3: #163F70;
  --ds-color-accent-4: #1C4D8A;
  --ds-color-accent-5: #215CA3;
  --ds-color-accent-6: #215CA3;
  --ds-color-accent-7: #4B87D1;
  --ds-color-accent-8: #85BCFF;
  --ds-color-accent-9: #85BCFF;
  --ds-color-accent-10: #A3CDFF;
  --ds-color-accent-11: #C2DEFF;
  --ds-color-accent-12: #A3CDFF;
  --ds-color-accent-13: #C2DEFF;
  --ds-color-accent-contrast-1: #0C2038;
  --ds-color-accent-contrast-2: #102D4F;
  --ds-color-accent-background-default: var(--ds-color-accent-1);
  --ds-color-accent-background-subtle: var(--ds-color-accent-2);
  --ds-color-accent-surface-default: var(--ds-color-accent-3);
  --ds-color-accent-surface-hover: var(--ds-color-accent-4);
  --ds-color-accent-surface-active: var(--ds-color-accent-5);
  --ds-color-accent-border-subtle: var(--ds-color-accent-6);
  --ds-color-accent-border-default: var(--ds-color-accent-7);
  --ds-color-accent-border-strong: var(--ds-color-accent-8);
  --ds-color-accent-base-default: var(--ds-color-accent-9);
  --ds-color-accent-base-hover: var(--ds-color-accent-10);
  --ds-color-accent-base-active: var(--ds-color-accent-11);
  --ds-color-accent-text-subtle: var(--ds-color-accent-12);
  --ds-color-accent-text-default: var(--ds-color-accent-13);
  --ds-color-accent-contrast-default: var(--ds-color-accent-contrast-1);
  --ds-color-accent-contrast-subtle: var(--ds-color-accent-contrast-2);
  --ds-color-neutral-1: #1B2129;
  --ds-color-neutral-2: #232A33;
  --ds-color-neutral-3: #2F3742;
  --ds-color-neutral-4: #3C4552;
  --ds-color-neutral-5: #495461;
  --ds-color-neutral-6: #495461;
  --ds-color-neutral-7: #6E7885;
  --ds-color-neutral-8: #B0B8C2;
  --ds-color-neutral-9: #B0B8C2;
  --ds-color-neutral-10: #D5DBE0;
  --ds-color-neutral-11: #EBEDF0;
  --ds-color-neutral-12: #D5DBE0;
  --ds-color-neutral-13: #EBEDF0;
  --ds-color-neutral-contrast-1: #1B2129;
  --ds-color-neutral-contrast-2: #232A33;
  --ds-color-neutral-background-default: var(--ds-color-neutral-1);
  --ds-color-neutral-background-subtle: var(--ds-color-neutral-2);
  --ds-color-neutral-surface-default: var(--ds-color-neutral-3);
  --ds-color-neutral-surface-hover: var(--ds-color-neutral-4);
  --ds-color-neutral-surface-active: var(--ds-color-neutral-5);
  --ds-color-neutral-border-subtle: var(--ds-color-neutral-6);
  --ds-color-neutral-border-default: var(--ds-color-neutral-7);
  --ds-color-neutral-border-strong: var(--ds-color-neutral-8);
  --ds-color-neutral-base-default: var(--ds-color-neutral-9);
  --ds-color-neutral-base-hover: var(--ds-color-neutral-10);
  --ds-color-neutral-base-active: var(--ds-color-neutral-11);
  --ds-color-neutral-text-subtle: var(--ds-color-neutral-12);
  --ds-color-neutral-text-default: var(--ds-color-neutral-13);
  --ds-color-neutral-contrast-default: var(--ds-color-neutral-contrast-1);
  --ds-color-neutral-contrast-subtle: var(--ds-color-neutral-contrast-2);
  --ds-color-brand1-1: #0C2038;
  --ds-color-brand1-2: #102D4F;
  --ds-color-brand1-3: #163F70;
  --ds-color-brand1-4: #1C4D8A;
  --ds-color-brand1-5: #215CA3;
  --ds-color-brand1-6: #215CA3;
  --ds-color-brand1-7: #4B87D1;
  --ds-color-brand1-8: #85BCFF;
  --ds-color-brand1-9: #85BCFF;
  --ds-color-brand1-10: #A3CDFF;
  --ds-color-brand1-11: #C2DEFF;
  --ds-color-brand1-12: #A3CDFF;
  --ds-color-brand1-13: #dee0e0;
  --ds-color-brand1-contrast-1: #0C2038;
  --ds-color-brand1-contrast-2: #102D4F;
  --ds-color-brand1-background-default: var(--ds-color-brand1-1);
  --ds-color-brand1-background-subtle: var(--ds-color-brand1-2);
  --ds-color-brand1-surface-default: var(--ds-color-brand1-3);
  --ds-color-brand1-surface-hover: var(--ds-color-brand1-4);
  --ds-color-brand1-surface-active: var(--ds-color-brand1-5);
  --ds-color-brand1-border-subtle: var(--ds-color-brand1-6);
  --ds-color-brand1-border-default: var(--ds-color-brand1-7);
  --ds-color-brand1-border-strong: var(--ds-color-brand1-8);
  --ds-color-brand1-base-default: var(--ds-color-brand1-9);
  --ds-color-brand1-base-hover: var(--ds-color-brand1-10);
  --ds-color-brand1-base-active: var(--ds-color-brand1-11);
  --ds-color-brand1-text-subtle: var(--ds-color-brand1-12);
  --ds-color-brand1-text-default: var(--ds-color-brand1-13);
  --ds-color-brand1-contrast-default: var(--ds-color-brand1-contrast-1);
  --ds-color-brand1-contrast-subtle: var(--ds-color-brand1-contrast-2);
  --ds-color-brand2-1: #291F15;
  --ds-color-brand2-2: #332517;
  --ds-color-brand2-3: #422D18;
  --ds-color-brand2-4: #614120;
  --ds-color-brand2-5: #7A4D1D;
  --ds-color-brand2-6: #7A4D1D;
  --ds-color-brand2-7: #BD8146;
  --ds-color-brand2-8: #FFBF80;
  --ds-color-brand2-9: #FFBF80;
  --ds-color-brand2-10: #FFDBB8;
  --ds-color-brand2-11: #FFEBD6;
  --ds-color-brand2-12: #FFDBB8;
  --ds-color-brand2-13: #FFEBD6;
  --ds-color-brand2-contrast-1: #291F15;
  --ds-color-brand2-contrast-2: #332517;
  --ds-color-brand2-background-default: var(--ds-color-brand2-1);
  --ds-color-brand2-background-subtle: var(--ds-color-brand2-2);
  --ds-color-brand2-surface-default: var(--ds-color-brand2-3);
  --ds-color-brand2-surface-hover: var(--ds-color-brand2-4);
  --ds-color-brand2-surface-active: var(--ds-color-brand2-5);
  --ds-color-brand2-border-subtle: var(--ds-color-brand2-6);
  --ds-color-brand2-border-default: var(--ds-color-brand2-7);
  --ds-color-brand2-border-strong: var(--ds-color-brand2-8);
  --ds-color-brand2-base-default: var(--ds-color-brand2-9);
  --ds-color-brand2-base-hover: var(--ds-color-brand2-10);
  --ds-color-brand2-base-active: var(--ds-color-brand2-11);
  --ds-color-brand2-text-subtle: var(--ds-color-brand2-12);
  --ds-color-brand2-text-default: var(--ds-color-brand2-13);
  --ds-color-brand2-contrast-default: var(--ds-color-brand2-contrast-1);
  --ds-color-brand2-contrast-subtle: var(--ds-color-brand2-contrast-2);
  --ds-color-brand3-1: #261A33;
  --ds-color-brand3-2: #2E1D40;
  --ds-color-brand3-3: #3F235C;
  --ds-color-brand3-4: #4E2A75;
  --ds-color-brand3-5: #5E318F;
  --ds-color-brand3-6: #5E318F;
  --ds-color-brand3-7: #915FC7;
  --ds-color-brand3-8: #CF9EFF;
  --ds-color-brand3-9: #DBB8FF;
  --ds-color-brand3-10: #CF9EFF;
  --ds-color-brand3-11: #E8D1FF;
  --ds-color-brand3-12: #DBB8FF;
  --ds-color-brand3-13: #E8D1FF;
  --ds-color-brand3-contrast-1: #261A33;
  --ds-color-brand3-contrast-2: #2E1D40;
  --ds-color-brand3-background-default: var(--ds-color-brand3-1);
  --ds-color-brand3-background-subtle: var(--ds-color-brand3-2);
  --ds-color-brand3-surface-default: var(--ds-color-brand3-3);
  --ds-color-brand3-surface-hover: var(--ds-color-brand3-4);
  --ds-color-brand3-surface-active: var(--ds-color-brand3-5);
  --ds-color-brand3-border-subtle: var(--ds-color-brand3-6);
  --ds-color-brand3-border-default: var(--ds-color-brand3-7);
  --ds-color-brand3-border-strong: var(--ds-color-brand3-8);
  --ds-color-brand3-base-default: var(--ds-color-brand3-9);
  --ds-color-brand3-base-hover: var(--ds-color-brand3-10);
  --ds-color-brand3-base-active: var(--ds-color-brand3-11);
  --ds-color-brand3-text-subtle: var(--ds-color-brand3-12);
  --ds-color-brand3-text-default: var(--ds-color-brand3-13);
  --ds-color-brand3-contrast-default: var(--ds-color-brand3-contrast-1);
  --ds-color-brand3-contrast-subtle: var(--ds-color-brand3-contrast-2);
  --ds-color-success-background-default: var(--ds-global-green-1);
  --ds-color-success-background-subtle: var(--ds-global-green-2);
  --ds-color-success-surface-default: var(--ds-global-green-3);
  --ds-color-success-surface-hover: var(--ds-global-green-4);
  --ds-color-success-surface-active: var(--ds-global-green-5);
  --ds-color-success-border-subtle: var(--ds-global-green-6);
  --ds-color-success-border-default: var(--ds-global-green-7);
  --ds-color-success-border-strong: var(--ds-global-green-8);
  --ds-color-success-base-default: var(--ds-global-green-9);
  --ds-color-success-base-hover: var(--ds-global-green-10);
  --ds-color-success-base-active: var(--ds-global-green-11);
  --ds-color-success-text-subtle: var(--ds-global-green-12);
  --ds-color-success-text-default: var(--ds-global-green-13);
  --ds-color-success-contrast-default: var(--ds-global-green-contrast-1);
  --ds-color-success-contrast-subtle: var(--ds-global-green-contrast-2);
  --ds-color-danger-background-default: var(--ds-global-red-1);
  --ds-color-danger-background-subtle: var(--ds-global-red-2);
  --ds-color-danger-surface-default: var(--ds-global-red-3);
  --ds-color-danger-surface-hover: var(--ds-global-red-4);
  --ds-color-danger-surface-active: var(--ds-global-red-5);
  --ds-color-danger-border-subtle: var(--ds-global-red-6);
  --ds-color-danger-border-default: var(--ds-global-red-7);
  --ds-color-danger-border-strong: var(--ds-global-red-8);
  --ds-color-danger-base-default: var(--ds-global-red-9);
  --ds-color-danger-base-hover: var(--ds-global-red-10);
  --ds-color-danger-base-active: var(--ds-global-red-11);
  --ds-color-danger-text-subtle: var(--ds-global-red-12);
  --ds-color-danger-text-default: var(--ds-global-red-13);
  --ds-color-danger-contrast-default: var(--ds-global-red-contrast-1);
  --ds-color-danger-contrast-subtle: var(--ds-global-red-contrast-2);
  --ds-color-info-background-default: #031d30;
  --ds-color-info-background-subtle: #032540;
  --ds-color-info-surface-default: #043256;
  --ds-color-info-surface-hover: #063f6c;
  --ds-color-info-surface-active: #074d84;
  --ds-color-info-border-subtle: #075490;
  --ds-color-info-border-default: #0c72c1;
  --ds-color-info-border-strong: #98c3e5;
  --ds-color-info-base-default: #2a84c8;
  --ds-color-info-base-hover: #0a6dba;
  --ds-color-info-base-active: #085998;
  --ds-color-info-text-subtle: #6ba9d9;
  --ds-color-info-text-default: #d3e5f4;
  --ds-color-info-contrast-default: #000000;
  --ds-color-info-contrast-subtle: #040c13;
  --ds-color-warning-background-default: var(--ds-global-yellow-1);
  --ds-color-warning-background-subtle: var(--ds-global-yellow-2);
  --ds-color-warning-surface-default: var(--ds-global-yellow-3);
  --ds-color-warning-surface-hover: var(--ds-global-yellow-4);
  --ds-color-warning-surface-active: var(--ds-global-yellow-5);
  --ds-color-warning-border-subtle: var(--ds-global-yellow-6);
  --ds-color-warning-border-default: var(--ds-global-yellow-7);
  --ds-color-warning-border-strong: var(--ds-global-yellow-8);
  --ds-color-warning-base-default: var(--ds-global-orange-9);
  --ds-color-warning-base-hover: var(--ds-global-orange-10);
  --ds-color-warning-base-active: var(--ds-global-orange-11);
  --ds-color-warning-text-subtle: var(--ds-global-orange-12);
  --ds-color-warning-text-default: var(--ds-global-orange-13);
  --ds-color-warning-contrast-default: var(--ds-global-yellow-contrast-1);
  --ds-color-warning-contrast-subtle: var(--ds-global-yellow-contrast-2);
  --ds-color-focus-inner: #131E2B;
  --ds-color-focus-outer: #ECC238;
color-scheme: dark;
}
 
@media (prefers-color-scheme: dark) {
  [data-ds-color-mode="auto"] {
  --ds-global-blue-1: #031d30;
  --ds-global-blue-2: #032540;
  --ds-global-blue-3: #043256;
  --ds-global-blue-4: #063f6c;
  --ds-global-blue-5: #074d84;
  --ds-global-blue-6: #075490;
  --ds-global-blue-7: #0c72c1;
  --ds-global-blue-8: #98c3e5;
  --ds-global-blue-9: #2a84c8;
  --ds-global-blue-10: #0a6dba;
  --ds-global-blue-11: #085998;
  --ds-global-blue-12: #6ba9d9;
  --ds-global-blue-13: #d3e5f4;
  --ds-global-blue-contrast-1: #000000;
  --ds-global-blue-contrast-2: #040c13;
  --ds-global-green-1: #022106;
  --ds-global-green-2: #022b08;
  --ds-global-green-3: #03380a;
  --ds-global-green-4: #04480d;
  --ds-global-green-5: #045810;
  --ds-global-green-6: #056011;
  --ds-global-green-7: #068117;
  --ds-global-green-8: #90cc98;
  --ds-global-green-9: #078818;
  --ds-global-green-10: #067114;
  --ds-global-green-11: #045a10;
  --ds-global-green-12: #5fb56b;
  --ds-global-green-13: #cfe9d3;
  --ds-global-green-contrast-1: #ffffff;
  --ds-global-green-contrast-2: #f9fcf9;
  --ds-global-orange-1: #2e1508;
  --ds-global-orange-2: #3a1b0a;
  --ds-global-orange-3: #50240d;
  --ds-global-orange-4: #652e10;
  --ds-global-orange-5: #7a3814;
  --ds-global-orange-6: #863d16;
  --ds-global-orange-7: #b5531e;
  --ds-global-orange-8: #e6b398;
  --ds-global-orange-9: #ba541e;
  --ds-global-orange-10: #994619;
  --ds-global-orange-11: #7a3814;
  --ds-global-orange-12: #db9169;
  --ds-global-orange-13: #f4ddd0;
  --ds-global-orange-contrast-1: #ffffff;
  --ds-global-orange-contrast-2: #fcf8f6;
  --ds-global-purple-1: #251238;
  --ds-global-purple-2: #301748;
  --ds-global-purple-3: #402060;
  --ds-global-purple-4: #52287a;
  --ds-global-purple-5: #643196;
  --ds-global-purple-6: #6b399d;
  --ds-global-purple-7: #865daf;
  --ds-global-purple-8: #c9b7db;
  --ds-global-purple-9: #b298cc;
  --ds-global-purple-10: #a181c0;
  --ds-global-purple-11: #8f69b5;
  --ds-global-purple-12: #b298cc;
  --ds-global-purple-13: #e7dfef;
  --ds-global-purple-contrast-1: #000000;
  --ds-global-purple-contrast-2: #18141b;
  --ds-global-red-1: #3b0808;
  --ds-global-red-2: #4b0a0a;
  --ds-global-red-3: #620e0e;
  --ds-global-red-4: #7c1212;
  --ds-global-red-5: #961515;
  --ds-global-red-6: #a31717;
  --ds-global-red-7: #c93c3c;
  --ds-global-red-8: #e9b0b0;
  --ds-global-red-9: #d76e6e;
  --ds-global-red-10: #ce4e4e;
  --ds-global-red-11: #c32727;
  --ds-global-red-12: #df8d8d;
  --ds-global-red-13: #f5dcdc;
  --ds-global-red-contrast-1: #000000;
  --ds-global-red-contrast-2: #271414;
  --ds-global-yellow-1: #221b06;
  --ds-global-yellow-2: #2b2307;
  --ds-global-yellow-3: #3a300a;
  --ds-global-yellow-4: #4a3d0d;
  --ds-global-yellow-5: #5a4a0f;
  --ds-global-yellow-6: #635011;
  --ds-global-yellow-7: #856d17;
  --ds-global-yellow-8: #e4ba27;
  --ds-global-yellow-9: #3d310a;
  --ds-global-yellow-10: #52430e;
  --ds-global-yellow-11: #695512;
  --ds-global-yellow-12: #c39f21;
  --ds-global-yellow-13: #f5e19b;
  --ds-global-yellow-contrast-1: #ffffff;
  --ds-global-yellow-contrast-2: #ccc9bf;
  --ds-color-accent-1: #0C2038;
  --ds-color-accent-2: #102D4F;
  --ds-color-accent-3: #163F70;
  --ds-color-accent-4: #1C4D8A;
  --ds-color-accent-5: #215CA3;
  --ds-color-accent-6: #215CA3;
  --ds-color-accent-7: #4B87D1;
  --ds-color-accent-8: #85BCFF;
  --ds-color-accent-9: #85BCFF;
  --ds-color-accent-10: #A3CDFF;
  --ds-color-accent-11: #C2DEFF;
  --ds-color-accent-12: #A3CDFF;
  --ds-color-accent-13: #C2DEFF;
  --ds-color-accent-contrast-1: #0C2038;
  --ds-color-accent-contrast-2: #102D4F;
  --ds-color-accent-background-default: var(--ds-color-accent-1);
  --ds-color-accent-background-subtle: var(--ds-color-accent-2);
  --ds-color-accent-surface-default: var(--ds-color-accent-3);
  --ds-color-accent-surface-hover: var(--ds-color-accent-4);
  --ds-color-accent-surface-active: var(--ds-color-accent-5);
  --ds-color-accent-border-subtle: var(--ds-color-accent-6);
  --ds-color-accent-border-default: var(--ds-color-accent-7);
  --ds-color-accent-border-strong: var(--ds-color-accent-8);
  --ds-color-accent-base-default: var(--ds-color-accent-9);
  --ds-color-accent-base-hover: var(--ds-color-accent-10);
  --ds-color-accent-base-active: var(--ds-color-accent-11);
  --ds-color-accent-text-subtle: var(--ds-color-accent-12);
  --ds-color-accent-text-default: var(--ds-color-accent-13);
  --ds-color-accent-contrast-default: var(--ds-color-accent-contrast-1);
  --ds-color-accent-contrast-subtle: var(--ds-color-accent-contrast-2);
  --ds-color-neutral-1: #1B2129;
  --ds-color-neutral-2: #232A33;
  --ds-color-neutral-3: #2F3742;
  --ds-color-neutral-4: #3C4552;
  --ds-color-neutral-5: #495461;
  --ds-color-neutral-6: #495461;
  --ds-color-neutral-7: #6E7885;
  --ds-color-neutral-8: #B0B8C2;
  --ds-color-neutral-9: #B0B8C2;
  --ds-color-neutral-10: #D5DBE0;
  --ds-color-neutral-11: #EBEDF0;
  --ds-color-neutral-12: #D5DBE0;
  --ds-color-neutral-13: #EBEDF0;
  --ds-color-neutral-contrast-1: #1B2129;
  --ds-color-neutral-contrast-2: #232A33;
  --ds-color-neutral-background-default: var(--ds-color-neutral-1);
  --ds-color-neutral-background-subtle: var(--ds-color-neutral-2);
  --ds-color-neutral-surface-default: var(--ds-color-neutral-3);
  --ds-color-neutral-surface-hover: var(--ds-color-neutral-4);
  --ds-color-neutral-surface-active: var(--ds-color-neutral-5);
  --ds-color-neutral-border-subtle: var(--ds-color-neutral-6);
  --ds-color-neutral-border-default: var(--ds-color-neutral-7);
  --ds-color-neutral-border-strong: var(--ds-color-neutral-8);
  --ds-color-neutral-base-default: var(--ds-color-neutral-9);
  --ds-color-neutral-base-hover: var(--ds-color-neutral-10);
  --ds-color-neutral-base-active: var(--ds-color-neutral-11);
  --ds-color-neutral-text-subtle: var(--ds-color-neutral-12);
  --ds-color-neutral-text-default: var(--ds-color-neutral-13);
  --ds-color-neutral-contrast-default: var(--ds-color-neutral-contrast-1);
  --ds-color-neutral-contrast-subtle: var(--ds-color-neutral-contrast-2);
  --ds-color-brand1-1: #0C2038;
  --ds-color-brand1-2: #102D4F;
  --ds-color-brand1-3: #163F70;
  --ds-color-brand1-4: #1C4D8A;
  --ds-color-brand1-5: #215CA3;
  --ds-color-brand1-6: #215CA3;
  --ds-color-brand1-7: #4B87D1;
  --ds-color-brand1-8: #85BCFF;
  --ds-color-brand1-9: #85BCFF;
  --ds-color-brand1-10: #A3CDFF;
  --ds-color-brand1-11: #C2DEFF;
  --ds-color-brand1-12: #A3CDFF;
  --ds-color-brand1-13: #dee0e0;
  --ds-color-brand1-contrast-1: #0C2038;
  --ds-color-brand1-contrast-2: #102D4F;
  --ds-color-brand1-background-default: var(--ds-color-brand1-1);
  --ds-color-brand1-background-subtle: var(--ds-color-brand1-2);
  --ds-color-brand1-surface-default: var(--ds-color-brand1-3);
  --ds-color-brand1-surface-hover: var(--ds-color-brand1-4);
  --ds-color-brand1-surface-active: var(--ds-color-brand1-5);
  --ds-color-brand1-border-subtle: var(--ds-color-brand1-6);
  --ds-color-brand1-border-default: var(--ds-color-brand1-7);
  --ds-color-brand1-border-strong: var(--ds-color-brand1-8);
  --ds-color-brand1-base-default: var(--ds-color-brand1-9);
  --ds-color-brand1-base-hover: var(--ds-color-brand1-10);
  --ds-color-brand1-base-active: var(--ds-color-brand1-11);
  --ds-color-brand1-text-subtle: var(--ds-color-brand1-12);
  --ds-color-brand1-text-default: var(--ds-color-brand1-13);
  --ds-color-brand1-contrast-default: var(--ds-color-brand1-contrast-1);
  --ds-color-brand1-contrast-subtle: var(--ds-color-brand1-contrast-2);
  --ds-color-brand2-1: #291F15;
  --ds-color-brand2-2: #332517;
  --ds-color-brand2-3: #422D18;
  --ds-color-brand2-4: #614120;
  --ds-color-brand2-5: #7A4D1D;
  --ds-color-brand2-6: #7A4D1D;
  --ds-color-brand2-7: #BD8146;
  --ds-color-brand2-8: #FFBF80;
  --ds-color-brand2-9: #FFBF80;
  --ds-color-brand2-10: #FFDBB8;
  --ds-color-brand2-11: #FFEBD6;
  --ds-color-brand2-12: #FFDBB8;
  --ds-color-brand2-13: #FFEBD6;
  --ds-color-brand2-contrast-1: #291F15;
  --ds-color-brand2-contrast-2: #332517;
  --ds-color-brand2-background-default: var(--ds-color-brand2-1);
  --ds-color-brand2-background-subtle: var(--ds-color-brand2-2);
  --ds-color-brand2-surface-default: var(--ds-color-brand2-3);
  --ds-color-brand2-surface-hover: var(--ds-color-brand2-4);
  --ds-color-brand2-surface-active: var(--ds-color-brand2-5);
  --ds-color-brand2-border-subtle: var(--ds-color-brand2-6);
  --ds-color-brand2-border-default: var(--ds-color-brand2-7);
  --ds-color-brand2-border-strong: var(--ds-color-brand2-8);
  --ds-color-brand2-base-default: var(--ds-color-brand2-9);
  --ds-color-brand2-base-hover: var(--ds-color-brand2-10);
  --ds-color-brand2-base-active: var(--ds-color-brand2-11);
  --ds-color-brand2-text-subtle: var(--ds-color-brand2-12);
  --ds-color-brand2-text-default: var(--ds-color-brand2-13);
  --ds-color-brand2-contrast-default: var(--ds-color-brand2-contrast-1);
  --ds-color-brand2-contrast-subtle: var(--ds-color-brand2-contrast-2);
  --ds-color-brand3-1: #261A33;
  --ds-color-brand3-2: #2E1D40;
  --ds-color-brand3-3: #3F235C;
  --ds-color-brand3-4: #4E2A75;
  --ds-color-brand3-5: #5E318F;
  --ds-color-brand3-6: #5E318F;
  --ds-color-brand3-7: #915FC7;
  --ds-color-brand3-8: #CF9EFF;
  --ds-color-brand3-9: #DBB8FF;
  --ds-color-brand3-10: #CF9EFF;
  --ds-color-brand3-11: #E8D1FF;
  --ds-color-brand3-12: #DBB8FF;
  --ds-color-brand3-13: #E8D1FF;
  --ds-color-brand3-contrast-1: #261A33;
  --ds-color-brand3-contrast-2: #2E1D40;
  --ds-color-brand3-background-default: var(--ds-color-brand3-1);
  --ds-color-brand3-background-subtle: var(--ds-color-brand3-2);
  --ds-color-brand3-surface-default: var(--ds-color-brand3-3);
  --ds-color-brand3-surface-hover: var(--ds-color-brand3-4);
  --ds-color-brand3-surface-active: var(--ds-color-brand3-5);
  --ds-color-brand3-border-subtle: var(--ds-color-brand3-6);
  --ds-color-brand3-border-default: var(--ds-color-brand3-7);
  --ds-color-brand3-border-strong: var(--ds-color-brand3-8);
  --ds-color-brand3-base-default: var(--ds-color-brand3-9);
  --ds-color-brand3-base-hover: var(--ds-color-brand3-10);
  --ds-color-brand3-base-active: var(--ds-color-brand3-11);
  --ds-color-brand3-text-subtle: var(--ds-color-brand3-12);
  --ds-color-brand3-text-default: var(--ds-color-brand3-13);
  --ds-color-brand3-contrast-default: var(--ds-color-brand3-contrast-1);
  --ds-color-brand3-contrast-subtle: var(--ds-color-brand3-contrast-2);
  --ds-color-success-background-default: var(--ds-global-green-1);
  --ds-color-success-background-subtle: var(--ds-global-green-2);
  --ds-color-success-surface-default: var(--ds-global-green-3);
  --ds-color-success-surface-hover: var(--ds-global-green-4);
  --ds-color-success-surface-active: var(--ds-global-green-5);
  --ds-color-success-border-subtle: var(--ds-global-green-6);
  --ds-color-success-border-default: var(--ds-global-green-7);
  --ds-color-success-border-strong: var(--ds-global-green-8);
  --ds-color-success-base-default: var(--ds-global-green-9);
  --ds-color-success-base-hover: var(--ds-global-green-10);
  --ds-color-success-base-active: var(--ds-global-green-11);
  --ds-color-success-text-subtle: var(--ds-global-green-12);
  --ds-color-success-text-default: var(--ds-global-green-13);
  --ds-color-success-contrast-default: var(--ds-global-green-contrast-1);
  --ds-color-success-contrast-subtle: var(--ds-global-green-contrast-2);
  --ds-color-danger-background-default: var(--ds-global-red-1);
  --ds-color-danger-background-subtle: var(--ds-global-red-2);
  --ds-color-danger-surface-default: var(--ds-global-red-3);
  --ds-color-danger-surface-hover: var(--ds-global-red-4);
  --ds-color-danger-surface-active: var(--ds-global-red-5);
  --ds-color-danger-border-subtle: var(--ds-global-red-6);
  --ds-color-danger-border-default: var(--ds-global-red-7);
  --ds-color-danger-border-strong: var(--ds-global-red-8);
  --ds-color-danger-base-default: var(--ds-global-red-9);
  --ds-color-danger-base-hover: var(--ds-global-red-10);
  --ds-color-danger-base-active: var(--ds-global-red-11);
  --ds-color-danger-text-subtle: var(--ds-global-red-12);
  --ds-color-danger-text-default: var(--ds-global-red-13);
  --ds-color-danger-contrast-default: var(--ds-global-red-contrast-1);
  --ds-color-danger-contrast-subtle: var(--ds-global-red-contrast-2);
  --ds-color-info-background-default: #031d30;
  --ds-color-info-background-subtle: #032540;
  --ds-color-info-surface-default: #043256;
  --ds-color-info-surface-hover: #063f6c;
  --ds-color-info-surface-active: #074d84;
  --ds-color-info-border-subtle: #075490;
  --ds-color-info-border-default: #0c72c1;
  --ds-color-info-border-strong: #98c3e5;
  --ds-color-info-base-default: #2a84c8;
  --ds-color-info-base-hover: #0a6dba;
  --ds-color-info-base-active: #085998;
  --ds-color-info-text-subtle: #6ba9d9;
  --ds-color-info-text-default: #d3e5f4;
  --ds-color-info-contrast-default: #000000;
  --ds-color-info-contrast-subtle: #040c13;
  --ds-color-warning-background-default: var(--ds-global-yellow-1);
  --ds-color-warning-background-subtle: var(--ds-global-yellow-2);
  --ds-color-warning-surface-default: var(--ds-global-yellow-3);
  --ds-color-warning-surface-hover: var(--ds-global-yellow-4);
  --ds-color-warning-surface-active: var(--ds-global-yellow-5);
  --ds-color-warning-border-subtle: var(--ds-global-yellow-6);
  --ds-color-warning-border-default: var(--ds-global-yellow-7);
  --ds-color-warning-border-strong: var(--ds-global-yellow-8);
  --ds-color-warning-base-default: var(--ds-global-orange-9);
  --ds-color-warning-base-hover: var(--ds-global-orange-10);
  --ds-color-warning-base-active: var(--ds-global-orange-11);
  --ds-color-warning-text-subtle: var(--ds-global-orange-12);
  --ds-color-warning-text-default: var(--ds-global-orange-13);
  --ds-color-warning-contrast-default: var(--ds-global-yellow-contrast-1);
  --ds-color-warning-contrast-subtle: var(--ds-global-yellow-contrast-2);
  --ds-color-focus-inner: #131E2B;
  --ds-color-focus-outer: #ECC238;
color-scheme: dark;
}

}

}

/**
 * These files are generated from design tokens defind using Token Studio
 */

@layer ds.theme.typography.primary {
:root, [data-ds-typography="primary"] {
  --ds-font-family: Inter;
  --ds-font-weight-medium: 500;
  --ds-font-weight-semibold: 600;
  --ds-font-weight-regular: 400;
  --ds-line-height-sm: 1.3;
  --ds-line-height-md: 1.5;
  --ds-line-height-lg: 1.7;
  --ds-font-size-1: 0.75rem;
  --ds-font-size-2: 0.8125rem;
  --ds-font-size-3: 0.875rem;
  --ds-font-size-4: 1rem;
  --ds-font-size-5: 1.125rem;
  --ds-font-size-6: 1.3125rem;
  --ds-font-size-7: 1.5rem;
  --ds-font-size-8: 1.875rem;
  --ds-font-size-9: 2.25rem;
  --ds-font-size-10: 3rem;
  --ds-font-size-11: 3.75rem;
  --ds-letter-spacing-1: -1%;
  --ds-letter-spacing-2: -0.5%;
  --ds-letter-spacing-3: -0.25%;
  --ds-letter-spacing-4: -0.15%;
  --ds-letter-spacing-5: 0%;
  --ds-letter-spacing-6: 0.15%;
  --ds-letter-spacing-7: 0.25%;
  --ds-letter-spacing-8: 0.5%;
  --ds-letter-spacing-9: 1.5%;

  .ds-error_message--xs {
    font-size: var(--ds-font-size-3);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-error_message--sm {
    font-size: var(--ds-font-size-4);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-error_message--md {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-error_message--lg {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-label--xs {
    font-size: var(--ds-font-size-3);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-label--sm {
    font-size: var(--ds-font-size-4);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-label--md {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-label--lg {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph-long--xs {
    font-size: var(--ds-font-size-3);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-paragraph-long--sm {
    font-size: var(--ds-font-size-4);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-paragraph-long--md {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph-long--lg {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph-short--xs {
    font-size: var(--ds-font-size-3);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-paragraph-short--sm {
    font-size: var(--ds-font-size-4);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-paragraph-short--md {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph-short--lg {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph--xs {
    font-size: var(--ds-font-size-3);
    line-height: var(--ds-line-height-md);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-paragraph--sm {
    font-size: var(--ds-font-size-4);
    line-height: var(--ds-line-height-md);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-paragraph--md {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-md);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-paragraph--lg {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-md);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-ingress--xs {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-ingress--sm {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-7);
  }

  .ds-ingress--md {
    font-size: var(--ds-font-size-7);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-ingress--lg {
    font-size: var(--ds-font-size-8);
    line-height: var(--ds-line-height-lg);
    font-weight: var(--ds-font-weight-regular);
    letter-spacing: var(--ds-letter-spacing-8);
  }

  .ds-heading--2xs {
    font-size: var(--ds-font-size-5);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-heading--xs {
    font-size: var(--ds-font-size-6);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-6);
  }

  .ds-heading--sm {
    font-size: var(--ds-font-size-7);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-5);
  }

  .ds-heading--md {
    font-size: var(--ds-font-size-8);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-3);
  }

  .ds-heading--lg {
    font-size: var(--ds-font-size-9);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-2);
  }

  .ds-heading--xl {
    font-size: var(--ds-font-size-10);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-1);
  }

  .ds-heading--2xl {
    font-size: var(--ds-font-size-11);
    line-height: var(--ds-line-height-sm);
    font-weight: var(--ds-font-weight-medium);
    letter-spacing: var(--ds-letter-spacing-1);
  }

  .ds-font-weight--medium {
    font-weight: 500;
  }

  .ds-font-weight--semibold {
    font-weight: 600;
  }

  .ds-font-weight--regular {
    font-weight: 400;
  }

  .ds-line-height--sm {
    line-height: 1.3;
  }

  .ds-line-height--md {
    line-height: 1.5;
  }

  .ds-line-height--lg {
    line-height: 1.7;
  }
}
}

@charset "UTF-8";

@layer ds.reset, ds.theme, ds.base, ds.utilities, ds.components;
/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/83651bee47cf14da-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/6fed4e5749a3ea15-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/062522b8b7c3ad6a-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e6f5886ae1242622-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/19e37deead9b3ec2-s.woff2) format('woff2');
  unicode-range: U+0307-0308, U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* math */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/46392699924ae7e5-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/dd4ab5b525bd804a-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9beef36ab83de3f0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/faac4ac11aa3d97b-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/3d9ea938b6afa941-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Open Sans Fallback';src: local("Arial");ascent-override: 101.65%;descent-override: 27.86%;line-gap-override: 0.00%;size-adjust: 105.15%
}.__className_86737b {font-family: 'Open Sans', 'Open Sans Fallback', sans-serif;font-style: normal
}

